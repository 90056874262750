import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import {DeleteModalComponent, HistoryModalComponent, RejectModalComponent} from "../shared/modals";
import { TaskService } from "../shared/services/task.service";
import { TaskList } from "../shared/models/taskList";
import { DashboardService } from "../shared/services/dashboard.service";
import { TableService } from "../shared/services/table.service";
import { UntypedFormBuilder, UntypedFormControl, Validators } from "@angular/forms";
import * as moment from 'moment';
import { AlertService } from '../shared/services/alert.service';

@Component({
  selector: 'app-task-details',
  templateUrl: './task-details.component.html',
  styleUrls: ['./task-details.component.scss']
})
export class TaskDetailsComponent implements OnInit {

  static readonly dateFields = ['presidentDateOfBirth', 'editorDateOfBirth', 'swissOlympicCardValidityDate', 'handoverDate'];
  static readonly DATE_FORMAT = "DD.MM.YYYY";
  
  public isChatOpen: boolean = false;
  public routerData;
  private taskBody = {
    Search: '',
    SearchVariables: null,
    SortFields: null,
    TableFields: []
  };
  public actionButtons = [];
  public loader: boolean = false;
  public generalForm;
  public taskList;
  public taskType: string = '';
  public formType: string;
  public comments;
  public uploadData;
  private currentTask;
  public historyData;
  constructor(
    private router: Router,
    private activeRouter: ActivatedRoute,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private taskService: TaskService,
    private dashboardService: DashboardService,
    private tableService: TableService,
    private alertService: AlertService
  ) {
    this.generalForm = this.formBuilder.group({
      createNewOrganisation: new UntypedFormControl('no'),
      createNewPerson: new UntypedFormControl('no'),
      createNewEditor: new UntypedFormControl('no'),
      commentApplicant: new UntypedFormControl(''),
      personLookup: new UntypedFormControl(''),
      organizationName: new UntypedFormControl(''),
      organizationLookup: new UntypedFormControl(''),
      organizationMembership: new UntypedFormControl({value: '', disabled: true}),
      organizationMembershipLookup: new UntypedFormControl(''),
      organizationWebsite: new UntypedFormControl(''),
      presidentTitle: new UntypedFormControl(''),
      presidentFirstName: new UntypedFormControl(''),
      presidentLastName: new UntypedFormControl(''),
      presidentAddress: new UntypedFormControl(''),
      presidentZip: new UntypedFormControl(''),
      presidentPlace: new UntypedFormControl(''),
      presidentDateOfBirth: new UntypedFormControl(''),
      presidentPhoneNumberPrivate: new UntypedFormControl(''),
      presidentPhoneNumberOffice: new UntypedFormControl(''),
      presidentPhoneNumberMobile: new UntypedFormControl(''),
      presidentEmail: new UntypedFormControl(''),
      presidentEmailPrivate: new UntypedFormControl(''),
      editorLookup: new UntypedFormControl(''),
      editorTitle: new UntypedFormControl(''),
      editorFirstName: new UntypedFormControl(''),
      editorLastName: new UntypedFormControl(''),
      editorDateOfBirth: new UntypedFormControl(''),
      editorAddress: new UntypedFormControl(''),
      editorZip: new UntypedFormControl(''),
      editorPlace: new UntypedFormControl(''),
      editorPhoneNumberPrivate: new UntypedFormControl(''),
      editorPhoneNumberOffice: new UntypedFormControl(''),
      editorPhoneNumberMobile: new UntypedFormControl(''),
      editorEmail: new UntypedFormControl(''),
      editorEmailPrivate: new UntypedFormControl(''),
      officeAddress: new UntypedFormControl(''),
      officeZip: new UntypedFormControl(''),
      officePlace: new UntypedFormControl(''),
      officeEmail: new UntypedFormControl(''),

      // 2
      accountOrganizationName: new UntypedFormControl(''),
      accountAddress: new UntypedFormControl(''),
      accountZip: new UntypedFormControl(''),
      accountPlace: new UntypedFormControl(''),
      bankName: new UntypedFormControl(''),
      iban: new UntypedFormControl(''),

      // 3
      notes: new UntypedFormControl(''),

      // type specific
      sportArt: new UntypedFormControl(''),
      sportArtLookup: new UntypedFormControl('', Validators.required),      
      
      //A
      eventName: new UntypedFormControl(''),
      eventDate: new UntypedFormControl(''),
      numberOfEventDays: new UntypedFormControl(''),    
      eventPlace: new UntypedFormControl(''),
      eventWebsite: new UntypedFormControl(''),
      numberOfParticipants: new UntypedFormControl(''),
      eventCosts: new UntypedFormControl(''),
      eventIncome: new UntypedFormControl(''),
      importance: new UntypedFormControl(''),
      rankingListWebLink: new UntypedFormControl(''),
      firstTimeRun: new UntypedFormControl(''),

      //B
      owner: new UntypedFormControl(''),
      description: new UntypedFormControl(''),
      costs: new UntypedFormControl(''),
      contribution: new UntypedFormControl(''),
      usage: new UntypedFormControl(''),
      
      //C
      facilitiesPlace: new UntypedFormControl(''),
      accessibility: new UntypedFormControl(''),
      safetyStandard: new UntypedFormControl(''),

      //D
      projectGoals: new UntypedFormControl(''),

      //E1
      swissOlympicCardNumber: new UntypedFormControl(''),
      swissOlympicCardValidityDate: new UntypedFormControl(''),
      sportsmanWebsite: new UntypedFormControl(''),

      //F
      numberOfTrainers: new UntypedFormControl(''),

      //H
      handoverPlace: new UntypedFormControl(''),
      handoverOccasion: new UntypedFormControl(''),
      handoverDate: new UntypedFormControl(''),
      awardFor: new UntypedFormControl(''),
      awardReason: new UntypedFormControl(''),

      //proof formType X, the submit of the webform is passing X
      //in staatos the formType will be set to the orignial formType of the dossier (e.g. A,H,..)
      sportsdbId: new UntypedFormControl(''),
      dvNumber: new UntypedFormControl(''),
      dvYear: new UntypedFormControl(''),
      totalcosts: new UntypedFormControl(''),
      formType: new UntypedFormControl('')
    });
  }

  ngOnInit() {
    this.loader = false;
    this.activeRouter.params.subscribe((res) => {
      this.routerData = res;
    });

    if (this.routerData) {
      if (this.taskService.taskList) {
        this.getTaskInformation();
      } else {
        this.dashboardService.getTaskList(this.taskBody).subscribe((res: TaskList) => {

          this.taskService.taskList = res.tasklistEntries.map((item) => {
            return {
              checked: false,
              activityName: item.activityName,
              activityId: item.activityId,
              documentId: item.documentId,
              workflowInstanceId: item.workflowInstanceId,
              ...this.tableService.isJson(item.taskTemplate) ? JSON.parse(item.taskTemplate) : item.taskTemplate
            };
          });

          this.getTaskInformation();
        });
      }
    }    
  }

  getTaskInformation() {
    this.taskService.getTaskInformation(this.routerData.activityId, this.routerData.workflowId).subscribe((res) => {
      console.log(res);
      this.currentTask = this.taskService.taskList.find(item => item.activityId === this.routerData.activityId && item.workflowInstanceId === this.routerData.workflowId);
      this.comments = {
        comments: res['WorkflowInstance']['document']['comments'].reverse(),
        documentId: this.currentTask.documentId
      };
      this.historyData = res['WorkflowInstance']['activityInstances'];
      this.uploadData = res['WorkflowInstance']['document']['additionalData'];
      this.taskType = this.currentTask['activityName'];
      this.formType = this.taskType.split(' -')[0];
      this.actionButtons = res['TaskFunctions'];
      this.setBackendValues(res['WorkflowInstance']['variables']);
      // set lookup fields as required based on the values and listen for changes in the radio buttons
      this.registerChangeListenersForBooleansAndSetUpValidators();
      if(this.taskType != 'Nachweisdokumente') {
        this.isChatOpen = true;
      }
      this.loader = true;
    });
  }

  makeActionButton(action, stepName) {
    switch (action) {
      case 'Complete':
        // submit the task if the form is valid, ignore the validity in case the taskType contains 'Visum' or 'Auszahlungsbemessung' (document review)
        if(this.generalForm.valid || (this.taskType.includes('Visum') || this.taskType.includes('Auszahlungsbemessung'))) {
          // submit the task
          this.actionWitTask(this.currentTask);
        } else {
          // display errors if the form is not valid by marking all fields as touched
          this.generalForm.markAllAsTouched();
          this.alertService.createAlert('FORM.ERROR', 'error');
        }
        break;

      case 'Reject':
        this.rejectAction();
        break;

      case 'Delete':
        this.deleteAction();
        break;

      default:
        this.defaultAction(stepName);
        break;
    }
  }

  rejectAction() {
    const rejectReason = new UntypedFormControl('', Validators.required);
    const dialogRef = this.dialog.open(RejectModalComponent, {
      data: rejectReason
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res && res.value) {
        this.taskService.rejectTask({DocumentId: this.currentTask.documentId, Comment: res.value}).subscribe((responce) => {
          this.actionWitTask(this.currentTask, {id: '_status', value: 'reject'});
        })
      }
    });
  }

  deleteAction() {
    const dialogRef = this.dialog.open(DeleteModalComponent);
    dialogRef.afterClosed().subscribe((res) => {
      // if the user pressed Ja in the confirmation dialog, delete the task
      if(res === true) {
        const data = {
          ActivityId: this.currentTask.activityId,
          WorkflowInstances: [{
              documentId: this.currentTask.documentId,
              workflowInstanceId: this.currentTask.workflowInstanceId
            }]
        };
        this.taskService.deleteTask(data).subscribe((res: any) => {
          if(res.result === 'ok') {
            this.router.navigate(['/dashboard']);
          } else {
            console.log(res);
            this.alertService.createAlert('DELETE.ERROR', 'error');
          }
        });
      }
    });
  }

  defaultAction(stepName) {
    console.log('prase');
    this.actionWitTask(this.currentTask, {id: '_moveToStep', value: stepName});
  }

  openChatModal() {
    this.isChatOpen = !this.isChatOpen;
  }

  openHistoryModal() {
    this.dialog.open(HistoryModalComponent, {
      data: this.historyData
    });
  }

  setBackendValues(backendVariables) {
    const keys = Object.keys(this.generalForm.controls);
    keys.forEach((key) => {
      const currentKey = backendVariables.find(item => item.id === key);
      this.generalForm.get(key).setValue(currentKey ? currentKey.value : this.generalForm.get(key).value);
      // if the incoming value is a one of the date values it needs to be parsed and converted to a date
      if(TaskDetailsComponent.dateFields.includes(key)) {
        // parse and set the date value only if it is not undefined
        if(currentKey) {
          let parsedDate = moment(currentKey.value, TaskDetailsComponent.DATE_FORMAT).toDate();
          // check if it was possible to parse the date value
          if(parsedDate.toString() === 'Invalid Date') {
            // if not, do not set the value in the form control and print a message
            console.log('could not parse date value of: ' + JSON.stringify(currentKey));
          } else {
            // if yes, set the parsed value as the value of the form control
            this.generalForm.get(key).setValue(parsedDate);
          }
        }
      }
    });
    //form type (public var) needs to be available for the dashboard and needs to be overwritten if formType is available as variable
    this.formType = backendVariables.find(item => item.id === 'formType').value;
    // patch the checkbox value to be valid boolean
    this.generalForm.get('firstTimeRun').setValue((this.generalForm.get('firstTimeRun').value === 'true'));
  }

  private registerChangeListenersForBooleansAndSetUpValidators() {
    // organization-related variables should be required only for forms other than E1
    if(this.formType !== 'E1') {
      let orgLookupFC: UntypedFormControl = this.generalForm.get('organizationLookup');
      this.setStateOfControlBasedOnRadioButtonValue(orgLookupFC, this.generalForm.get('createNewOrganisation').value);
      // listen for changes of createNewOrganisation radio button
      this.generalForm.get('createNewOrganisation').valueChanges.subscribe(booleanValue => {       
        let orgFC: UntypedFormControl = this.generalForm.get('organizationName');
        if (booleanValue === 'no') {
          // if the value in the radio button is no the lookup should be required and the field with original value disabled
          this.makeFormControlEnabledAndRequired(orgLookupFC);
          orgFC.disable();
        } 
        if(booleanValue === 'yes') {
          // if the value in the radio button is yes the lookup should be cleared and disabled and the field with original value enabled
          this.makeFormControlDisabled(orgLookupFC);
          orgFC.enable();
        }
      });
    } else {
      // remove required validator from organizationMembershipLookup
      this.generalForm.get('organizationMembershipLookup').setValidators(null);
      // make the date of birth mandatory for E1 form
      this.generalForm.get('presidentDateOfBirth').setValidators([Validators.required]);
    }   

    let personLookupFC: UntypedFormControl = this.generalForm.get('personLookup');
    this.setStateOfControlBasedOnRadioButtonValue(personLookupFC, this.generalForm.get('createNewPerson').value);
     // listen for changes of createNewPerson radio button
     this.generalForm.get('createNewPerson').valueChanges.subscribe(booleanValue => { 
      this.setStateOfControlBasedOnRadioButtonValue(personLookupFC, booleanValue);
    });

    //make the editorLookup required only if the editorLastName has non-empty value
    if(this.generalForm.get('editorLastName').value !== '') {  
      let editorLookupFC: UntypedFormControl = this.generalForm.get('editorLookup');
      this.setStateOfControlBasedOnRadioButtonValue(editorLookupFC, this.generalForm.get('createNewEditor').value);
      // listen for changes of createNewEditor radio button
      this.generalForm.get('createNewEditor').valueChanges.subscribe(booleanValue => { 
        this.setStateOfControlBasedOnRadioButtonValue(editorLookupFC, booleanValue);
      });
    }
  }

  private setStateOfControlBasedOnRadioButtonValue(formControl: UntypedFormControl, booleanValue: string) {
    if (booleanValue === 'no') {
      // if the value in the radio button is no the lookup should be required
      this.makeFormControlEnabledAndRequired(formControl);
    } 
    if(booleanValue === 'yes') {
      // if the value in the radio button is yes the lookup should be cleared and disabled
     this.makeFormControlDisabled(formControl);
    }
  }

  private makeFormControlEnabledAndRequired(formControl: UntypedFormControl) {
    formControl.setValidators([Validators.required]);
    formControl.enable();
  }

  private makeFormControlDisabled(formControl: UntypedFormControl) {
    formControl.setValidators(null);
    formControl.setValue(null);
    formControl.disable();
  }

  actionWitTask(taskData, additionalData?) {
    const data = {
      DocumentIds: [taskData.documentId],
      MessageData: {
        activityId: taskData.activityId,
        workflowInstances: [{
          variables: [],
          workflowInstanceId: taskData.workflowInstanceId
        }]
      }
    };

    const keys = Object.keys(this.generalForm.controls);

    keys.forEach((key) => {
      if(TaskDetailsComponent.dateFields.includes(key)) {
        // date value - format the date as a string before sending it to the backend
        let val = this.generalForm.get(key).value;
        if(val) {
          // format the date to DD.MM.YYYY if the value is not empty
          data.MessageData.workflowInstances[0].variables.push({id: key, value: moment(val).format(TaskDetailsComponent.DATE_FORMAT)});
        } else {
          // set the empty value (might have been cleared by the user)
          data.MessageData.workflowInstances[0].variables.push({id: key, value: val});
        }
      } else {
        // standard text value, no formatting necessary
        data.MessageData.workflowInstances[0].variables.push({id: key, value: this.generalForm.get(key).value});
      }
    });

    // only add the additional data if they are defined
    if(additionalData) {
      data.MessageData.workflowInstances[0].variables.push(additionalData);
    }
    
    this.taskService.makeActionWithTask(data).subscribe((res) => {
      console.log(res);
      this.router.navigate(['/dashboard']);
    });
  }
}
