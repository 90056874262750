// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-content {
  box-sizing: border-box;
  margin-top: 80px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 80px);
}
.main-content form {
  display: flex;
  flex-direction: column;
  width: 300px;
}
.main-content form button {
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/login/login.component.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,gBAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,0BAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;EACA,YAAA;AACJ;AACI;EACE,gBAAA;AACN","sourcesContent":[".main-content {\n  box-sizing: border-box;\n  margin-top: 80px;\n  padding: 15px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: calc(100vh - 80px);\n\n  form {\n    display: flex;\n    flex-direction: column;\n    width: 300px;\n\n    button {\n      margin-top: 20px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
