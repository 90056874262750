import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-history-modal',
  templateUrl: './history-modal.component.html',
  styleUrls: ['./history-modal.component.scss']
})
export class HistoryModalComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<HistoryModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
    // sort data by startDateTime DESC
    this.data.sort((a: any,b: any) => new Date(b.startDateTime).getTime() - new Date(a.startDateTime).getTime());
  }

}
