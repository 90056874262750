import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from "@angular/core";

@Injectable()
export class SessionInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const session = sessionStorage.getItem('session');

    if (session) {
      return next.handle(request.clone({
        headers: request.headers.set('X-Session-Id', session)
      }));
    } else {
      return next.handle(request.clone());
    }
  }
}
