import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../shared/services/authentication.service';
import { AlertService } from '../shared/services/alert.service';
import { SideMenuService } from "../shared/services/side-menu.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm;
  loader: boolean = false;
  constructor(
    private fb: UntypedFormBuilder,
    private http: HttpClient,
    private authService: AuthenticationService,
    private router: Router,
    private alertService: AlertService,
    private sideMenuService: SideMenuService
  ) {
    this.loginForm = this.fb.group({
      LoginName: new UntypedFormControl('', Validators.required),
      Password: new UntypedFormControl('', Validators.required)
    });
  }

  ngOnInit() {
  }

  submitForm() {
    this.loader = true;
    this.authService.login(this.loginForm.value).subscribe((res: any) => {
      if (!res.ErrorMessage) {
        this.authService.getAccountInformation().subscribe((res2: any) => {
          this.loader = false;
          console.log(res2);
          sessionStorage.setItem('currentUser', res2.AccountLoginName);
          this.authService.currentUserInformation.next(res2.AccountLoginName);
          this.sideMenuService.activityNames = res2.ActivityNames;
          sessionStorage.setItem('activities', res2.ActivityNames);
          this.router.navigate(['/dashboard']);
        });
      } else {
        this.loader = false;
        this.alertService.createAlert(res.ErrorMessage, 'error');
      }
    });
  }
}
