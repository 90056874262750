import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private url = environment.url;
  public dataForTable = new Subject();

  constructor(private http: HttpClient) { }

  getTaskList(body) {
    return this.http.post(this.url + '/Service/MyHomeService.svc/GetTasklist', body);
  }

  makeActionWithTask(body) {
    return this.http.post(this.url + '/Service/WorkflowService.svc/MessageWorkflow', body);
  }

  rejectTask(body) {
    return this.http.post(this.url + '/Service/MyHomeService.svc/AddComment', body);
  }

  getUsersList() {
    return this.http.get('https://effektif-connector.cumuluspro.net/ConnectorService.svc/json/Interact//6b3c74aa-7580-47ba-817a-f3fd0881d39d/getreassignList');
  }

  getDocument(session): Observable<Blob> {
    return this.http.get('https://effektif-connector.cumuluspro.net/ConnectorService.svc/json/AsyncStorage/af574d7d-57af-4333-b136-70464c4788f6.pdf?s=' + session,
      {
        responseType: 'blob'
      });
  }

  getTaskInformation(activityId, workflowId) {
    return this.http.get(this.url + '/Service/MyHomeService.svc/GetTaskInfo?workflowStepId=' + activityId + '&workflowInstanceId=' + workflowId);
  }
}
