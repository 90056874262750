import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertComponent } from '../alert/alert.component';

@Injectable({ providedIn: 'root' })
export class AlertService {
  message: string = '';
  type: string = '';

  constructor(private snackBar: MatSnackBar) {}

  createAlert(message: string, type) {
    this.message = message;
    this.type = type;
    this.snackBar.openFromComponent(AlertComponent, {
      duration: 6000,
      panelClass: type === 'success' ? 'success-alert' : 'error-alert'
    });
  }
}
