// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
.container > div {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;
}
.container > div mat-form-field {
  margin-right: 30px;
}
.container > div mat-radio-button {
  margin: 0px 20px;
}

.viewer-wrapper {
  margin-top: 20px;
  background-color: #f5f5f5;
  overflow: auto;
}

.buttons-wrapper {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
.container .first-container-item {
  width: 60%;
}
.container .first-container-item div {
  width: 100%;
  padding: 10px 0px;
}
.container .first-container-item div:nth-child(2) mat-form-field {
  min-width: 45%;
}
.container .second-container-item {
  width: 38%;
}
.container .second-container-item div {
  width: 100%;
  padding: 10px 0px;
}
.container .second-container-item div:nth-child(2) mat-form-field {
  min-width: 45%;
}`, "",{"version":3,"sources":["webpack://./src/app/task-details/viewer/viewer.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,eAAA;AACF;AACE;EACE,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;EACA,WAAA;EACA,sBAAA;AACJ;AACI;EACE,kBAAA;AACN;AAEI;EACE,gBAAA;AAAN;;AAIA;EACE,gBAAA;EACA,yBAAA;EACA,cAAA;AADF;;AAKA;EACE,gBAAA;EACA,aAAA;EACA,uBAAA;AAFF;;AAKA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,eAAA;AAFF;AAIE;EACE,UAAA;AAFJ;AAII;EACE,WAAA;EACA,iBAAA;AAFN;AAKQ;EACE,cAAA;AAHV;AASE;EACE,UAAA;AAPJ;AASI;EACE,WAAA;EACA,iBAAA;AAPN;AAUQ;EACE,cAAA;AARV","sourcesContent":[".container {\n  margin-top: 30px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  flex-wrap: wrap;\n\n  >div {\n    margin-top: 15px;\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    width: 100%;\n    box-sizing: border-box;\n\n    mat-form-field {\n      margin-right: 30px;\n    }\n\n    mat-radio-button {\n      margin: 0px 20px;\n    }\n  }\n}\n.viewer-wrapper {\n  margin-top: 20px;\n  background-color: #f5f5f5;\n  overflow: auto;\n  \n}\n\n.buttons-wrapper {\n  margin-top: 10px;\n  display: flex;\n  justify-content: center;\n}\n\n.container {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  flex-wrap: wrap;\n\n  .first-container-item {\n    width: 60%;\n\n    div {\n      width: 100%;\n      padding: 10px 0px;\n\n      &:nth-child(2) {\n        mat-form-field {\n          min-width: 45%;\n        }\n      }\n    }\n  }\n\n  .second-container-item {\n    width: 38%;\n\n    div {\n      width: 100%;\n      padding: 10px 0px;\n\n      &:nth-child(2) {\n        mat-form-field {\n          min-width: 45%;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
