// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  margin-top: 30px;
}
.container > div {
  margin-top: 15px;
  border: 1px solid #d7d7d7;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
}
.container > div mat-form-field {
  margin-right: 30px;
}
.container > div mat-radio-button {
  margin: 0px 20px;
}

.first-container mat-form-field:nth-child(1), .first-container mat-form-field:nth-child(2) {
  min-width: 21%;
}
.first-container mat-form-field:nth-child(3) {
  min-width: 50%;
}
.first-container > div {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
.first-container > div > div:first-child {
  margin-bottom: 20px;
}
.first-container > div mat-radio-button {
  margin: 0px 20px;
}

.second-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
.second-container .second-container-item {
  width: 48%;
}
.second-container .second-container-item div {
  width: 100%;
  padding: 10px 0px;
}
.second-container .second-container-item div:nth-child(2) mat-form-field {
  min-width: 45%;
}

.comment mat-form-field {
  width: 100%;
}

.third-container mat-form-field {
  min-width: 14%;
}`, "",{"version":3,"sources":["webpack://./src/app/task-details/first-tab-proof/first-tab-proof.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AACE;EACE,gBAAA;EACA,yBAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;EACA,WAAA;EACA,sBAAA;EACA,aAAA;AACJ;AACI;EACE,kBAAA;AACN;AAEI;EACE,gBAAA;AAAN;;AAOI;EACE,cAAA;AAJN;AAOI;EACE,cAAA;AALN;AASE;EACE,aAAA;EACA,sBAAA;EACA,iBAAA;AAPJ;AAUM;EACE,mBAAA;AARR;AAYI;EACE,gBAAA;AAVN;;AAeA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,eAAA;AAZF;AAcE;EACE,UAAA;AAZJ;AAcI;EACE,WAAA;EACA,iBAAA;AAZN;AAeQ;EACE,cAAA;AAbV;;AAqBE;EACE,WAAA;AAlBJ;;AAuBE;EACE,cAAA;AApBJ","sourcesContent":[".container {\n  margin-top: 30px;\n\n  >div {\n    margin-top: 15px;\n    border: 1px solid #d7d7d7;\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    width: 100%;\n    box-sizing: border-box;\n    padding: 20px;\n\n    mat-form-field {\n      margin-right: 30px;\n    }\n\n    mat-radio-button {\n      margin: 0px 20px;\n    }\n  }\n}\n\n.first-container {\n  mat-form-field {\n    &:nth-child(1), &:nth-child(2) {\n      min-width: 21%;\n    }\n\n    &:nth-child(3) {\n      min-width: 50%;\n    }\n  }\n\n  >div {\n    display: flex;\n    flex-direction: column;\n    flex-wrap: nowrap;\n\n    & > div {\n      &:first-child {\n        margin-bottom: 20px;\n      }\n    }\n\n    mat-radio-button {\n      margin: 0px 20px;\n    }\n  }\n}\n\n.second-container {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  flex-wrap: wrap;\n\n  .second-container-item {\n    width: 48%;\n\n    div {\n      width: 100%;\n      padding: 10px 0px;\n\n      &:nth-child(2) {\n        mat-form-field {\n          min-width: 45%;\n        }\n      }\n    }\n  }\n}\n\n.comment {\n  mat-form-field {\n    width: 100%;\n  }\n}\n\n.third-container {\n  mat-form-field {\n    min-width: 14%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
