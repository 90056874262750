import { NgModule } from '@angular/core';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTreeModule } from '@angular/material/tree';
import { MatBadgeModule } from '@angular/material/badge';
import { MatChipsModule } from '@angular/material/chips';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDividerModule } from '@angular/material/divider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRadioModule } from '@angular/material/radio';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { DateAdapter } from '@angular/material/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';

import { MomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';

export const DATE_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'DD.MM.YYYY',
    monthYearA11yLabel: 'MM YYYY',
  },
};

@NgModule({
  imports: [
    MatButtonModule,
    MatSidenavModule,
    MatButtonModule,
    MatTableModule,
    MatSortModule,
    MatInputModule,
    MatDialogModule,
    MatCheckboxModule,
    MatTreeModule,
    CdkTableModule,
    CdkTreeModule,
    MatBadgeModule,
    MatChipsModule,
    DragDropModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatDividerModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatMenuModule,
    MatTabsModule,
    MatRadioModule,
    MomentDateModule
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'de-CH'},
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS }
  ],
  exports: [
    MatButtonModule,
    MatSidenavModule,
    MatButtonModule,
    MatTableModule,
    MatSortModule,
    MatInputModule,
    MatDialogModule,
    MatCheckboxModule,
    MatTreeModule,
    CdkTableModule,
    CdkTreeModule,
    MatBadgeModule,
    MatChipsModule,
    DragDropModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatDividerModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatDatepickerModule,
    //MatNativeDateModule,
    MatMenuModule,
    MatTabsModule,
    MatRadioModule,
    MomentDateModule
  ]
})
export class MaterialModule {}
