import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, UntypedFormArray} from '@angular/forms';
import { Router } from '@angular/router';
import { TableService } from '../../shared/services/table.service';

@Component({
  selector: 'app-dashboard-table',
  templateUrl: './dashboard-table.component.html',
  styleUrls: ['./dashboard-table.component.scss']
})
export class DashboardTableComponent implements OnInit, OnChanges {
  @Input() tableData;
  @Input() columnHeaders;
  public keys;
  public checkedItems;
  public directionArrow = false;
  public copyTableData;
  public currentColumnForSorting = 'documentId';
  public selectAllControl: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private tableService: TableService
    ) {
    this.checkedItems = this.formBuilder.group({
      items: new UntypedFormArray([])
    });
  }

  ngOnInit() {}

  ngOnChanges() {
    // sort the data by taskCreatedDateTime in descending order (newest first)
    this.tableData.sort((a, b) => {
      return new Date(b.taskCreatedDateTime).getTime() - new Date(a.taskCreatedDateTime).getTime();
    });
    this.copyTableData = this.tableData;

    if (this.tableData.length > 0) {
      this.setCheckedElements();
    }
  }

  setCheckedElements() {
    this.tableData.forEach((item) => {
      this.checkedItems.get('items').push(new UntypedFormGroup({
        checked: new UntypedFormControl(item.checked)
      }));
    });
  }

  unselectAllElements() {
    this.checkedItems.get('items').controls.forEach((item) => {
      item.setValue({checked: false});
    });
  }


  selectItem(index) {
    this.checkedItems.get('items').controls[index].patchValue({checked: !!this.checkedItems.get('items').controls[index].value.checked});
  }

  selectAll() {
    this.checkedItems.get('items').controls.forEach((item, index) => {
      if (this.selectAllControl !== item.value.checked) {
        this.selectItem(index);
      }
      item.setValue({checked: this.selectAllControl});
    });
  }

  openItem(item) {
    this.router.navigate(['task-details',  item.activityId, item.workflowInstanceId]);
  }
}
