// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-content {
  margin-top: 80px;
  min-height: calc(100vh - 80px);
  padding: 30px;
  box-sizing: border-box;
}
.main-content .actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 10px 0px;
}
.main-content .actions > div:first-child button {
  margin-right: 20px;
}

app-chat-sticky {
  width: 400px;
  height: 60vh;
  position: fixed;
  right: 10px;
  bottom: 0px;
  z-index: 100;
}

iframe {
  height: calc(100vh - 176px);
}`, "",{"version":3,"sources":["webpack://./src/app/task-details/task-details.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,8BAAA;EACA,aAAA;EACA,sBAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,eAAA;EACA,gBAAA;AACJ;AAGQ;EACE,kBAAA;AADV;;AAQA;EACE,YAAA;EACA,YAAA;EACA,eAAA;EACA,WAAA;EACA,WAAA;EACA,YAAA;AALF;;AAQA;EACE,2BAAA;AALF","sourcesContent":[".main-content {\n  margin-top: 80px;\n  min-height: calc(100vh - 80px);\n  padding: 30px;\n  box-sizing: border-box;\n\n  .actions {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    flex-wrap: wrap;\n    margin: 10px 0px;\n\n    >div {\n      &:first-child {\n        button {\n          margin-right: 20px;\n        }\n      }\n    }\n  }\n}\n\napp-chat-sticky {\n  width: 400px;\n  height: 60vh;\n  position: fixed;\n  right: 10px;\n  bottom: 0px;\n  z-index: 100;\n}\n\niframe {\n  height: calc(100vh - 176px);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
