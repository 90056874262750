import { Component, OnInit, Input } from '@angular/core';
import {UntypedFormGroup} from "@angular/forms";
import {TaskService} from "../../shared/services/task.service";
import { debounceTime, distinctUntilChanged} from "rxjs/operators";

@Component({
  selector: 'app-first-tab',
  templateUrl: './first-tab.component.html',
  styleUrls: ['./first-tab.component.scss']
})
export class FirstTabComponent implements OnInit {
  public organizationOptions;
  public loader: boolean;
  public personOptions;
  public editorOptions;
  public organizationMembershipOptions;
  @Input() form: UntypedFormGroup;
  @Input() formType: string;
  @Input() activityId: string = '';

  constructor(
    private taskService: TaskService
  ) { }

  ngOnInit() {
    const organisationName = this.form.get('organizationLookup').value;
    const organisationMembership = this.form.get('organizationMembershipLookup').value;
    const personName = this.form.get('personLookup').value;
    const editorName = this.form.get('editorLookup').value;
    //TODO this is a hotfix - setting loader to true should not be necessary
    this.loader = true;
    if (organisationName) {
      this.getOptionsForOrganization(organisationName);
    }
    if(organisationMembership) {
      this.getOptionsForOrganizationMembership(organisationMembership);
    }

    if (personName) {
      this.getPersonName(personName);
    }

    if (editorName) {
      this.getEditorName(editorName);
    }

    this.form.get('organizationLookup').valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged()
      )
      .subscribe((res) => {
      this.getOptionsForOrganization(res);
    });

    this.form.get('personLookup').valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged()
      )
      .subscribe((res) => {
        this.getPersonName(res);
      });

    this.form.get('editorLookup').valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged()
      )
      .subscribe((res) => {
        this.getEditorName(res);
      });
      
    this.form.get('organizationMembershipLookup').valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged()
      )
      .subscribe((res) => {
        this.getOptionsForOrganizationMembership(res);
      });
  }

  getOptionsForOrganization(value) {
    this.loader = false;
    this.taskService.getOrganisationNames('Graubuenden_Organisationen', value, this.activityId).subscribe((res: {suggestions: any[]}) => {
      this.organizationOptions = res.suggestions.map((item) => {
        return item.value;
      });
      this.loader = true;
    });
  }

  getPersonName(value) {
    this.loader = false;
    this.taskService.getOrganisationNames('Graubuenden_Personen', value, this.activityId).subscribe((res: {suggestions: any[]}) => {
      this.personOptions = res.suggestions.map((item) => {
        return item.value;
      });
      this.loader = true;
    });
  }

  getEditorName(value) {
    this.loader = false;
    this.taskService.getOrganisationNames('Graubuenden_Personen', value, this.activityId).subscribe((res: {suggestions: any[]}) => {
      this.editorOptions = res.suggestions.map((item) => {
        return item.value;
      });
      this.loader = true;
    });
  }

  getOptionsForOrganizationMembership(value) {
    this.loader = false;
    this.taskService.getOrganisationNames('Graubuenden_Membership', value, this.activityId).subscribe((res: {suggestions: any[]}) => {
      this.organizationMembershipOptions = res.suggestions.map((item) => {
        return item.value;
      });
      this.loader = true;
    });
  }

  hasError(controlName: string, errorName: string) : boolean {
    return this.form.controls[controlName].hasError(errorName);
  }
}
