import { Component, OnInit, Inject, inject } from '@angular/core';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { AlertService } from '../services/alert.service';
@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  public message;
  public type;
  constructor(@Inject(MAT_SNACK_BAR_DEFAULT_OPTIONS) public data: {message: string, type: string}) { }
  private readonly alertService: AlertService = inject(AlertService);

  ngOnInit() {
    this.type = this.alertService.type;
    this.message = this.alertService.message;
  }

}
