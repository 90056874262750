import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DashboardService } from '../shared/services/dashboard.service';
import { TableService } from '../shared/services/table.service';
import { TaskList } from "../shared/models/taskList";
import { TABLE_COLUMNS } from "./columns";
import {TaskService} from "../shared/services/task.service";
import { SideMenuService } from '../shared/services/side-menu.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public data = [];
  public loader: boolean = false;
  public opened: boolean = true;
  private taskBody = {
    Search: '',
    SearchVariables: null,
    SortFields: null,
    TableFields: []
  };
  public arrayOfTasks;
  public currentTableItem;
  public displayedTableColumns;

  constructor(
    private dialog: MatDialog,
    private dashboardService: DashboardService,
    private tableService: TableService,
    private taskService: TaskService,
    private sideMenuService: SideMenuService
  ) {}

  ngOnInit() {
    this.getTaskList(null);
  }

  getTaskList(currentlySelectedItem) {
    this.dashboardService.getTaskList(this.taskBody).subscribe((res: TaskList) => {
      console.log(res);
      this.arrayOfTasks = res.tasklistEntries.map(item => {
        return {
          activityName: item.activityName,
          activityId: item.activityId,
          documentId: item.documentId,
          workflowInstanceId: item.workflowInstanceId
        };
        
      });

      this.taskService.taskList = res.tasklistEntries.map((item) => {
        return {
          checked: false,
          activityName: item.activityName,
          activityId: item.activityId,
          documentId: item.documentId,
          workflowInstanceId: item.workflowInstanceId,
          createdDateTime: item.createdDateTime,
          ...this.tableService.isJson(item.taskTemplate) ? JSON.parse(item.taskTemplate) : item.taskTemplate
        };
      });

      this.sideMenuService.sortMenuItemsAndUpdateTaskNumbers(this.arrayOfTasks);
      this.loader = true;
      // if currentlySelectedItem is null use last selected item from sideMenuService
      if (currentlySelectedItem === null) {
        // this happens when the method is invoked from ngOnInit
        currentlySelectedItem = this.sideMenuService.getLastSelectedMenuItem();
      }
      // update the table data (including columns) only when the getTaskList method is invoked from ngOnInit
      // filter elements whose activityName matches the received event to be displayed in the table
      // we have to use startsWith to compare the event and activityName because of the number of tasks in brackets (simple === would not work)
      this.taskService.taskList
        ? this.currentTableItem = this.taskService.taskList.filter(item => currentlySelectedItem.startsWith(item.activityName))
        : this.currentTableItem = [];
      // prepare the columns to be shown (again use startsWith for string comparison)
      this.displayedTableColumns = TABLE_COLUMNS.find(item => currentlySelectedItem.startsWith(item.table))
        ? TABLE_COLUMNS.find(item => currentlySelectedItem.startsWith(item.table)).columns
        : TABLE_COLUMNS.find(item => item.table === 'all').columns;
    });
  }

  unselectAllItems() {
    this.data.forEach((item) => {
      item.checked = false;
    });
  }

  get getCheckedItems() {
    return this.data.some(item => item.checked === true);
  }

  onMenuItemChange(event) {
    // retrieve the task list and update the menu (numbers in brackets) and table with the new data
    this.getTaskList(event);
  }
}
