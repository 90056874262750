// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  width: 100%;
  height: 80px;
  padding: 10px 30px 10px 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 3px 5px rgba(57, 63, 72, 0.1);
  position: fixed;
  top: 0;
  background-color: white;
  z-index: 10;
}
.header .left-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.header .left-content img {
  height: 60px;
}
.header .right-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.header .right-content:hover {
  cursor: pointer;
}
.header .right-content fa {
  color: #0d4c90;
}
.header .right-content .profile-image {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.header .right-content .profile-image img {
  margin-left: 15px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
}
.header .right-content .profile-image img:hover {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/header/header.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,4BAAA;EACA,sBAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;EAGA,2CAAA;EACA,eAAA;EACA,MAAA;EACA,uBAAA;EACA,WAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;AACJ;AACI;EACE,YAAA;AACN;AAGE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;AADJ;AAGI;EACE,eAAA;AADN;AAII;EACE,cAAA;AAFN;AAKI;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;AAHN;AAKM;EACE,iBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;AAHR;AAKQ;EACE,eAAA;AAHV","sourcesContent":[".header {\n  width: 100%;\n  height: 80px;\n  padding: 10px 30px 10px 15px;\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  -webkit-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.1);\n  -moz-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.1);\n  box-shadow: 0 3px 5px rgba(57, 63, 72, 0.1);\n  position: fixed;\n  top: 0;\n  background-color: white;\n  z-index: 10;\n\n  .left-content {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n\n    img {\n      height: 60px;\n    }\n  }\n\n  .right-content {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n\n    &:hover {\n      cursor: pointer;\n    }\n\n    fa {\n      color: #0d4c90;\n    }\n\n    .profile-image {\n      display: flex;\n      flex-direction: row;\n      align-items: center;\n\n      img {\n        margin-left: 15px;\n        width: 35px;\n        height: 35px;\n        border-radius: 50%;\n\n        &:hover {\n          cursor: pointer;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
