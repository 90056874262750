import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from "@angular/forms";

@Component({
  selector: 'app-second-tab',
  templateUrl: './second-tab.component.html',
  styleUrls: ['./second-tab.component.scss']
})
export class SecondTabComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  constructor() { }

  ngOnInit() {
  }

}
