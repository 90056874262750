import {Injectable} from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TableService {
  public updateSelectItems = new Subject();
  constructor() {}

  sortStringInTable (direction, data, label) {
    const arrowDirection = direction ? 1 : -1;

    data.sort((a, b) => {
      if (a[label] == null) {
        a[label] = '';
      }

      if (a[label].toUpperCase() < b[label].toUpperCase()) {
        return arrowDirection;
      } else if ( a[label].toUpperCase() > b[label].toUpperCase()) {
        return -1 * arrowDirection;
      } else {
        return 0;
      }
    });
  }

  sortNumberInTable (direction, data, item) {
    const arrowDirection = direction;
    data.sort((a, b) => {

      if (!arrowDirection) {
        return a[item] - b[item];
      } else if (arrowDirection) {
        return b[item] - a[item];
      } else {
        return 0;
      }
    });
  }

  public isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
}
