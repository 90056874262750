import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private url = environment.url;
  public currentUserInformation = new Subject();

  constructor(private http: HttpClient) {}

  isAuthenticated() {
    return !!sessionStorage.getItem('session');
  }

  login(data) {
    return this.http.post(this.url + '/Service/AuthService.svc/Login', data).pipe(tap((res: any) => {
      if (!res.ErrorMessage) {
        sessionStorage.setItem('session', res.SessionId);
      }
  }));
  }

  logout() {
    sessionStorage.removeItem('session');
    sessionStorage.removeItem('currentUser');
    sessionStorage.removeItem('activities');
  }

  resetPassword(data) {
    return this.http.post(this.url + 'Service/AuthService.svc/ResetPassword', data);
  }

  getAccountInformation() {
    let date = new Date().getTime().toString();
    let params = new HttpParams();
    params = params.append('_', date);
    return this.http.get(this.url + '/Service/MyHomeService.svc/MyHome', {params: params});
  }
}
