// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.items {
  display: flex;
  flex-direction: column;
}
.items .item {
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: 5px 10px;
  font-size: 14px;
  border-bottom: 1px solid #f1f1f1;
}
.items .item:hover {
  cursor: pointer;
  background-color: #f3f3f3;
}
.items .active {
  background-color: #d7d7d7;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/side-menu/side-menu.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,eAAA;EACA,gCAAA;AACJ;AACI;EACE,eAAA;EACA,yBAAA;AACN;AAGE;EACE,yBAAA;AADJ","sourcesContent":[".items {\n  display: flex;\n  flex-direction: column;\n\n  .item {\n    width: 100%;\n    border: none;\n    text-align: left;\n    outline: none;\n    box-sizing: border-box;\n    display: flex;\n    flex-direction: row;\n    padding: 5px 10px;\n    font-size: 14px;\n    border-bottom: 1px solid #f1f1f1;;\n\n    &:hover {\n      cursor: pointer;\n      background-color: #f3f3f3;\n    }\n  }\n\n  .active {\n    background-color: #d7d7d7;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
