import {Component, OnInit, Input} from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.scss']
})
export class ViewerComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() documentId;
  @Input() formType: string;
  @Input() taskType: string;  
  @Input() documents;

  // URL of the PDF document retrieved from additional data (undefined if the document was not found in additional data)
  url: string;

  // current zoom factor of the PDF viewer
  currentZoom = 1;  

  constructor() { }

  ngOnInit() {
    console.log(this.taskType);
    if(this.taskType === 'Visum Bemessung' || this.taskType === 'Visum abgelehnt') {
      this.showBemessungDocument();
    } else {
      this.showAuszahlungsbemessungDocument();
    }    
  }  
 
  showBemessungDocument() {    
    this.url = this.searchAdditionalDataByKey(this.documents, '(B|b)emessung.*\.pdf');
  }

  showBeschlussDocument() {    
    this.url = this.searchAdditionalDataByKey(this.documents, '(B|b)eschluss.*\.pdf');
  }

  showAuszahlungsbemessungDocument() {    
    this.url = this.searchAdditionalDataByKey(this.documents, '(A|a)uszahlungsbemessung.*\.pdf');
  }

  zoomIn() {
    // add 0.2 to the current zoom
    this.currentZoom = this.currentZoom + 0.2;
  }

  zoomOut() {
    // subtract 0.2 to the current zoom
    this.currentZoom = this.currentZoom - 0.2;
  }

  searchAdditionalDataByKey(additionalData, keyToSearch) {
    var urlToReturn;
    additionalData.forEach(function(file, i) {
      // check if the key of the file contains the passed regex
      var regex = RegExp(keyToSearch);
      if(regex.test(file.key)) {
        urlToReturn = file.url;
      }
    });
    
    if(urlToReturn) {
      //  if the key was found append the sessionId
      urlToReturn = urlToReturn + '?s=' + sessionStorage.getItem('session');
    }    
    return urlToReturn;
  }
}
