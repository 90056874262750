import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material.module';
import { HeaderComponent } from './header/header.component';
import { DashboardService } from './services/dashboard.service';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { AlertComponent } from './alert/alert.component';
import {
  ChatStickyComponent,
  HistoryModalComponent,
  RejectModalComponent,
  DeleteModalComponent
} from './modals';
import {TaskService} from "./services/task.service";
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        MatIconModule,
        MatProgressSpinnerModule
    ],
    declarations: [
        HeaderComponent,
        SideMenuComponent,
        AlertComponent,
        ChatStickyComponent,
        HistoryModalComponent,
        RejectModalComponent,
        DeleteModalComponent
    ],
    exports: [
        HeaderComponent,
        SideMenuComponent,
        AlertComponent,
        ChatStickyComponent,
        HistoryModalComponent,
        RejectModalComponent,
        DeleteModalComponent,
        MatIconModule,
        MatProgressSpinnerModule
    ],
    providers: [
        DashboardService,
        TaskService
    ]
})

export class SharedModule { }
