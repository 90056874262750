export const TABLE_COLUMNS = [
  {
    "table": "all", //Name to the table which matches left navigation option for user to select. IF the name is 'all', then that is a default setting for all tables which do not have  a specific entry.
    "columns": [
      {
        "name": "taskCreatedDateTime", //this links back to the 'field' in from the taskTemplate
        "label": "Erfassungsdatum" //display label as column header
      },
      {
        "name": "organizationName",
        "label": "Organisation Name"
      },
      {
        "name": "bezeichnung",
        "label": "Bezeichnung"
      },
      {
        "name": "presidentLastName",
        "label": "Name"
      },
      {
        "name": "presidentFirstName",
        "label": "Vorname"
      },
      {
        "name": "presidentZip",
        "label": "PLZ"
      },
      {
        "name": "sportArt",
        "label": "Sportart"
      }
    ]
  },
  {
    "table": "A - Sportveranstaltungen", //Name to the table which matches left navigation option for user to select
    "columns": [
      {
        "name": "taskCreatedDateTime", //this links back to the 'field' in from the taskTemplate
        "label": "Erfassungsdatum" //display label as column header
      },
      {
        "name": "organizationName",
        "label": "Organisation Name"
      },
      {
        "name": "presidentLastName",
        "label": "Präsident Name"
      },
      {
        "name": "presidentFirstName",
        "label": "Präsident Vorname"
      },
      {
        "name": "presidentZip",
        "label": "PLZ"
      },
      {
        "name": "sportArt",
        "label": "Sportart"
      },
      {
        "name": "eventName",
        "label": "Veranstaltung"
      },
      {
        "name": "eventDate",
        "label": "Veranstaltungsdatum"
      },
      {
        "name": "eventPlace",
        "label": "Veranstaltungsort"
      },
      {
        "name": "importance",
        "label": "Wichtigkeit"
      }
    ]
  },
  {
    "table": "B - Sportmaterial und -geräte",
    "columns": [
      {
        "name": "taskCreatedDateTime",
        "label": "Erfassungsdatum"
      },
      {
        "name": "organizationName",
        "label": "Organisation Name"
      },
      {
        "name": "presidentLastName",
        "label": "Präsident Name"
      },
      {
        "name": "presidentFirstName",
        "label": "Präsident Vorname"
      },
      {
        "name": "presidentZip",
        "label": "PLZ"
      },
      {
        "name": "sportArt",
        "label": "Sportart"
      }
    ]
  },
  {
    "table": "C - Sportanlagen und -bauten",
    "columns": [
      {
        "name": "taskCreatedDateTime",
        "label": "Erfassungsdatum"
      },
      {
        "name": "organizationName",
        "label": "Organisation Name"
      },
      {
        "name": "presidentLastName",
        "label": "Präsident Name"
      },
      {
        "name": "presidentFirstName",
        "label": "Präsident Vorname"
      },
      {
        "name": "presidentZip",
        "label": "PLZ"
      },
      {
        "name": "sportArt",
        "label": "Sportart"
      }
    ]
  },
  {
    "table": "D - Projekte zur Sportförderung",
    "columns": [
      {
        "name": "taskCreatedDateTime",
        "label": "Erfassungsdatum"
      },
      {
        "name": "organizationName",
        "label": "Organisation Name"
      },
      {
        "name": "presidentLastName",
        "label": "Präsident Name"
      },
      {
        "name": "presidentFirstName",
        "label": "Präsident Vorname"
      },
      {
        "name": "presidentZip",
        "label": "PLZ"
      },
      {
        "name": "sportArt",
        "label": "Sportart"
      }
    ]
  },
  {
    "table": "F - Arge Alp",
    "columns": [
      {
        "name": "taskCreatedDateTime",
        "label": "Erfassungsdatum"
      },
      {
        "name": "organizationName",
        "label": "Organisation Name"
      },
      {
        "name": "presidentLastName",
        "label": "Präsident Name"
      },
      {
        "name": "presidentFirstName",
        "label": "Präsident Vorname"
      },
      {
        "name": "presidentZip",
        "label": "PLZ"
      },
      {
        "name": "sportArt",
        "label": "Sportart"
      },
      {
        "name": "eventName",
        "label": "Veranstaltungsname"
      },
      {
        "name": "eventDate",
        "label": "Veranstaltungsdatum"
      },
      {
        "name": "eventPlace",
        "label": "Veranstaltungsort"
      }
    ]
  },
  {
    "table": "H - Verbandssportpreis",
    "columns": [
      {
        "name": "taskCreatedDateTime",
        "label": "Erfassungsdatum"
      },
      {
        "name": "organizationName",
        "label": "Organisation Name"
      },
      {
        "name": "presidentLastName",
        "label": "Präsident Name"
      },
      {
        "name": "presidentFirstName",
        "label": "Präsident Vorname"
      },
      {
        "name": "presidentZip",
        "label": "PLZ"
      },
      {
        "name": "sportArt",
        "label": "Sportart"
      }
    ]
  },
  {
    "table": "E1 - Sportlerförderung",
    "columns": [
      {
        "name": "taskCreatedDateTime",
        "label": "Erfassungsdatum"
      },
      {
        "name": "presidentLastName",
        "label": "Sportler Name"
      },
      {
        "name": "presidentFirstName",
        "label": "Sportler Vorname"
      },
      {
        "name": "presidentZip",
        "label": "PLZ"
      }
    ]
  },
  {
    "table": "Fertigstellung EKUD",
    "columns": [
      {
        "name": "taskCreatedDateTime",
        "label": "Erfassungsdatum"
      },
      {
        "name": "organizationName",
        "label": "Organisation Name"
      },
      {
        "name": "bezeichnung",
        "label": "Bezeichnung"
      },
      {
        "name": "presidentLastName",
        "label": "Name"
      },
      {
        "name": "presidentFirstName",
        "label": "Vorname"
      },
      {
        "name": "presidentZip",
        "label": "PLZ"
      },
      {
        "name": "sportArt",
        "label": "Sportart"
      },
      {
        "name": "sentToAxioma",
        "label": "Weitergeleitet an Axioma"
      }
    ]
  },
]
