import { Component, OnInit, Input } from '@angular/core';
import {FormGroup, FormControl} from "@angular/forms";
import {TaskService} from "../../shared/services/task.service";

@Component({
  selector: 'app-first-tab-proof',
  templateUrl: './first-tab-proof.component.html',
  styleUrls: ['./first-tab-proof.component.scss']
})
export class FirstTabProofComponent implements OnInit {
  public loader: boolean;

  @Input() form: FormGroup;
  @Input() formType: string;
  // controls whether the top block with DV number, year etc. is shown or not (shown by default)
  @Input() showTopBlock: boolean = true;
  
  constructor(private taskService: TaskService) { }
  
  ngOnInit(): void {
    //TODO this is a hotfix - setting loader to true should not be necessary
    this.loader = true;
    this.form.disable();
    this.form.get('dvNumber').enable();
    this.form.get('dvYear').enable();
    this.form.get('sportsdbId').enable();
    this.form.get('formType').enable();
    this.form.get('formType').setValue(this.formType);
  }
}
