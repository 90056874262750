// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `mat-sidenav {
  width: 200px;
  border-right: none;
  box-shadow: 5px 0px 5px 0px rgba(57, 63, 72, 0.1);
}

.mat-drawer.mat-drawer-side {
  z-index: 11;
}

mat-sidenav-content .container-actions {
  display: flex;
  flex-direction: row;
}

mat-sidenav-container {
  z-index: inherit;
}

.main-content {
  margin-top: 80px;
  height: calc(100vh - 80px);
}
.main-content .content {
  padding: 20px;
}
.main-content .content table {
  width: 100%;
}
.main-content .content table .task-status {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

@media screen and (max-width: 930px) {
  mat-sidenav-content .container-actions {
    display: flex;
    flex-direction: column;
  }
  mat-sidenav-content .container-actions > div:last-child {
    padding: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  .main-content .content {
    padding: 5px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/dashboard/dashboard.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,kBAAA;EAGA,iDAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAGE;EACE,aAAA;EACA,mBAAA;AAAJ;;AAKA;EACE,gBAAA;AAFF;;AAKA;EACE,gBAAA;EACA,0BAAA;AAFF;AAIE;EACE,aAAA;AAFJ;AAII;EACE,WAAA;AAFN;AAIM;EACE,WAAA;EACA,YAAA;EACA,kBAAA;AAFR;;AAQA;EAEI;IACE,aAAA;IACA,sBAAA;EANJ;EASI;IACE,YAAA;IACA,aAAA;IACA,mBAAA;IACA,eAAA;IACA,uBAAA;EAPN;EAaE;IACE,YAAA;EAXJ;AACF","sourcesContent":["mat-sidenav {\n  width: 200px;\n  border-right: none;\n  -webkit-box-shadow: 5px 0px 5px 0px rgba(57, 63, 72, 0.1);\n  -moz-box-shadow: 5px 0px 5px 0px rgba(57, 63, 72, 0.1);\n  box-shadow: 5px 0px 5px 0px rgba(57, 63, 72, 0.1);\n}\n\n.mat-drawer.mat-drawer-side {\n  z-index: 11;\n}\n\nmat-sidenav-content {\n  .container-actions {\n    display: flex;\n    flex-direction: row;\n\n  }\n}\n\nmat-sidenav-container {\n  z-index: inherit;\n}\n\n.main-content {\n  margin-top: 80px;\n  height: calc(100vh - 80px);\n\n  .content {\n    padding: 20px;\n\n    table {\n      width: 100%;\n\n      .task-status {\n        width: 15px;\n        height: 15px;\n        border-radius: 50%;\n      }\n    }\n  }\n}\n\n@media screen and (max-width: 930px) {\n  mat-sidenav-content {\n    .container-actions {\n      display: flex;\n      flex-direction: column;\n\n\n      >div:last-child {\n        padding: 5px;\n        display: flex;\n        flex-direction: row;\n        flex-wrap: wrap;\n        justify-content: center;\n      }\n    }\n  }\n\n  .main-content {\n    .content {\n      padding: 5px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
