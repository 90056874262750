import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SideMenuService {
  public activityNames = [];

  menuItemsChange: Subject<any[]> = new Subject<any[]>();
  menuItems: any[] = [];

  lastSelectedMenuItem: string;

  constructor() {}

  public setLastSelectedMenuItem(item: string) {
    this.lastSelectedMenuItem = item;
  }

  /**
   * Returns the last selected menu item if it is defined, returns the first activityName otherwise
   */
  public getLastSelectedMenuItem() {
    if(this.lastSelectedMenuItem) {
      return this.lastSelectedMenuItem;
    } else {
      return sessionStorage.getItem('activities').split(',')[0];
    }    
  }

  sortMenuItemsAndUpdateTaskNumbers(arrayOfTasks: []) {
    this.menuItems = [];
    this.addElementsStartingWithToItems(arrayOfTasks, this.activityNames, "A - ");
    this.addElementsStartingWithToItems(arrayOfTasks, this.activityNames, "B - ");
    this.addElementsStartingWithToItems(arrayOfTasks, this.activityNames, "C - ");
    this.addElementsStartingWithToItems(arrayOfTasks, this.activityNames, "D - ");
    this.addElementsStartingWithToItems(arrayOfTasks, this.activityNames, "E1 - ");
    this.addElementsStartingWithToItems(arrayOfTasks, this.activityNames, "F - ");
    this.addElementsStartingWithToItems(arrayOfTasks, this.activityNames, "H - ");
    this.addElementsStartingWithToItems(arrayOfTasks, this.activityNames, "Fertigstellung SportDB");
    this.addElementsStartingWithToItems(arrayOfTasks, this.activityNames, "Visum Bemessung");
    this.addElementsStartingWithToItems(arrayOfTasks, this.activityNames, "Visum abgelehnt");
    this.addElementsStartingWithToItems(arrayOfTasks, this.activityNames, "Fertigstellung EKUD");
    this.addElementsStartingWithToItems(arrayOfTasks, this.activityNames, "EKUD zurückgewiesen");
    this.addElementsStartingWithToItems(arrayOfTasks, this.activityNames, "EKUD abgelehnt");
    this.addElementsStartingWithToItems(arrayOfTasks, this.activityNames, "Nachweisdokumente");
    this.addElementsStartingWithToItems(arrayOfTasks, this.activityNames, "Fertigstellung Auszahlung SportDB");
    this.addElementsStartingWithToItems(arrayOfTasks, this.activityNames, "Auszahlungsbemessung nicht akzeptiert");
    this.addElementsStartingWithToItems(arrayOfTasks, this.activityNames, "Visum Auszahlungsbemessung");
    this.menuItemsChange.next(this.menuItems);
  }

  private addElementsStartingWithToItems(arrayOfTasks: any[], elements: string[], startsWith: string) {
    elements.forEach((element) => {
      if (element.startsWith(startsWith)) {
        let filteredTasks: any[] = arrayOfTasks.filter((task: any) => task.activityName.startsWith(startsWith));
        // include the number of tasks in brackets
        this.menuItems.push(element + ' (' + filteredTasks.length + ')');
      }
    });
  }
}
