// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  margin-top: 30px;
}
.container > div {
  margin-top: 15px;
  border: 1px solid #d7d7d7;
  width: 44%;
  box-sizing: border-box;
  padding: 20px;
}

.first-container > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.first-container #files {
  margin: 20px 0px;
  cursor: pointer;
}
.first-container input[type=file] {
  display: none;
}
.first-container mat-form-field {
  width: 44%;
}`, "",{"version":3,"sources":["webpack://./src/app/task-details/attachments/attachments.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AACE;EACE,gBAAA;EACA,yBAAA;EACA,UAAA;EACA,sBAAA;EACA,aAAA;AACJ;;AAIE;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,WAAA;AADJ;AAIE;EACE,gBAAA;EACA,eAAA;AAFJ;AAKE;EACE,aAAA;AAHJ;AAME;EACE,UAAA;AAJJ","sourcesContent":[".container {\n  margin-top: 30px;\n\n  >div {\n    margin-top: 15px;\n    border: 1px solid #d7d7d7;\n    width: 44%;\n    box-sizing: border-box;\n    padding: 20px;\n  }\n}\n\n.first-container {\n  >div {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    width: 100%\n  }\n\n  #files {\n    margin: 20px 0px;\n    cursor: pointer;\n  }\n\n  input[type=\"file\"] {\n    display: none;\n  }\n\n  mat-form-field {\n    width: 44%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
