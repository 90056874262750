import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public isAuthenticated: boolean = false;
  public currentUser;

  constructor(
    private router: Router,
    private authService: AuthenticationService
  ) { }

  ngOnInit() {
    this.isAuthenticated = this.authService.isAuthenticated();
    this.currentUser = sessionStorage.getItem('currentUser');
    this.authService.currentUserInformation.subscribe((res) => {
      this.currentUser = res;
    });
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  getVersion() {
    return environment.version;
  }
}
