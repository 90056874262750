import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { SideMenuService } from "../services/side-menu.service";

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {
  public items = [];
  @Output() selectedItem = new EventEmitter();

  taskNumber;

  
  constructor(
    private sideMenuService: SideMenuService
  ) { }

  ngOnInit() {
    if(this.sideMenuService.activityNames.length <= 0) {
      this.sideMenuService.activityNames = sessionStorage.getItem('activities').split(',');
    }
    this.sideMenuService.menuItemsChange.subscribe(menuItems => {
      this.items = menuItems;
    });      
  }

  setActiveItem(item) {
    this.sideMenuService.setLastSelectedMenuItem(item);
    this.selectedItem.emit(item);
  }

  isItemActive(item): boolean {
    // compare (using startsWith function) the value from the menu with the LastSelectedMenuItem, return true if the values match
    return this.sideMenuService.getLastSelectedMenuItem().startsWith(item.slice(0, -4));
  }
 
}
