import {Component, OnInit, Output, ViewChild, ElementRef, AfterViewChecked, EventEmitter, Input} from '@angular/core';
import {TaskService} from "../../services/task.service";

@Component({
  selector: 'app-chat-sticky',
  templateUrl: './chat-sticky.component.html',
  styleUrls: ['./chat-sticky.component.scss']
})
export class ChatStickyComponent implements OnInit, AfterViewChecked {
  @ViewChild('scrollMe', {static: false}) private myScrollContainer: ElementRef;
  @Input() data;
  public textMessage;
  @Output() closeModal = new EventEmitter();

  constructor(
    private taskService: TaskService
  ) { }

  ngOnInit() {
    this.scrollToBottom();
    console.log(this.data);
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  sendMessage() {
    this.data.comments.push({
      comment: this.textMessage,
      name: sessionStorage.getItem('currentUser'),
      timestampDate: new Date()
    });
    this.taskService.sendMessage({
      'DocumentId': this.data.documentId,
      'Comment': this.textMessage
    }).subscribe((res) => {
      console.log(res);
    });
    this.textMessage = '';
  }

  scrollToBottom() {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch(err) {}
  }

  closeChat() {
    this.closeModal.emit(false);
  }

}
