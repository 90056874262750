// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container > div {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
}
.container > div mat-form-field {
  margin-right: 30px;
}
.container #checkbox {
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.container #checkbox mat-checkbox {
  margin-right: 20px;
}
.container #checkbox span {
  width: 80%;
}

.first-container mat-form-field:nth-child(1), .first-container mat-form-field:nth-child(2) {
  min-width: 21%;
}
.first-container mat-form-field:nth-child(3) {
  min-width: 50%;
}
.first-container > div {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
.first-container > div > div:first-child {
  margin-bottom: 20px;
}
.first-container > div mat-radio-button {
  margin: 0px 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/task-details/third-tab/third-tab.component.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;EACA,WAAA;EACA,sBAAA;EACA,aAAA;AAAJ;AAEI;EACE,kBAAA;AAAN;AAIE;EACE,UAAA;EACA,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,mBAAA;EACA,eAAA;AAFJ;AAII;EACE,kBAAA;AAFN;AAKI;EACE,UAAA;AAHN;;AAUI;EACE,cAAA;AAPN;AAUI;EACE,cAAA;AARN;AAYE;EACE,aAAA;EACA,sBAAA;EACA,iBAAA;AAVJ;AAaM;EACE,mBAAA;AAXR;AAeI;EACE,gBAAA;AAbN","sourcesContent":[".container {  \n  >div {\n    margin-top: 15px;\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    width: 100%;\n    box-sizing: border-box;\n    padding: 20px;\n\n    mat-form-field {\n      margin-right: 30px;\n    }\n  }\n\n  #checkbox {\n    width: 40%;\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-start;\n    align-items: center;\n    flex-wrap: wrap;\n\n    mat-checkbox {\n      margin-right: 20px;\n    }\n\n    span {\n      width: 80%;\n    }\n  }\n}\n\n.first-container {\n  mat-form-field {\n    &:nth-child(1), &:nth-child(2) {\n      min-width: 21%;\n    }\n\n    &:nth-child(3) {\n      min-width: 50%;\n    }\n  }\n\n  >div {\n    display: flex;\n    flex-direction: column;\n    flex-wrap: nowrap;\n\n    & > div {\n      &:first-child {\n        margin-bottom: 20px;\n      }\n    }\n\n    mat-radio-button {\n      margin: 0px 20px;\n    }\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
