import {Component, OnInit, Input} from '@angular/core';
import { TaskService } from 'src/app/shared/services/task.service';
import { UntypedFormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged} from "rxjs/operators";

@Component({
  selector: 'app-third-tab',
  templateUrl: './third-tab.component.html',
  styleUrls: ['./third-tab.component.scss']
})
export class ThirdTabComponent implements OnInit {
  @Input() formType: string = '';
  public loader: boolean = true;
  public sportartOptions;

  @Input() form: UntypedFormGroup;
  @Input() activityId: string = '';

  constructor(private taskService: TaskService) {}

  ngOnInit() {
    console.log(this.formType);
    const sportart = this.form.get('sportArtLookup').value;
    if (sportart) {
      this.getOptionsForSportart(sportart);
    }

    this.form.get('sportArtLookup').valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged()
      )
      .subscribe((res) => {
      this.getOptionsForSportart(res);
    });
  }

  getOptionsForSportart(value) {
    this.loader = false;
    this.taskService.getOrganisationNames('Graubuenden_Sportarten', value, this.activityId).subscribe((res: {suggestions: any[]}) => {
      this.sportartOptions = res.suggestions.map((item) => {
        return item.value;
      });
      this.loader = true;
    });
  }

  hasError(controlName: string, errorName: string) : boolean {
    return this.form.controls[controlName].hasError(errorName);
  }
}
