// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  border: 1px solid #dee2e6;
  width: 100%;
  border-collapse: collapse;
}
table thead tr {
  min-height: 56px;
}
table thead tr th {
  padding: 10px;
  border: 1px solid #dee2e6;
  text-align: left;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-weight: 500;
}
table thead tr th span:hover {
  cursor: pointer;
}
table thead tr th > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
table thead tr th > div > div {
  display: flex;
  flex-direction: row;
}
table thead tr th > div fa {
  margin-left: 10px;
  color: rgba(0, 0, 0, 0.5);
}
table tbody tr {
  min-height: 25px;
}
table tbody tr td {
  padding: 10px;
  border: 1px solid #dee2e6;
}
table tbody tr td span {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}

.model-action {
  position: absolute;
  right: 1.5rem;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/styles/table.scss","webpack://./src/app/shared/modals/history-modal/history-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,WAAA;EACA,yBAAA;ACCF;ADGI;EACE,gBAAA;ACDN;ADGM;EACE,aAAA;EACA,yBAAA;EACA,gBAAA;EACA,0BAAA;EACA,eAAA;EACA,gBAAA;ACDR;ADIU;EACE,eAAA;ACFZ;ADMQ;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;ACJV;ADMU;EACE,aAAA;EACA,mBAAA;ACJZ;ADOU;EACE,iBAAA;EACA,yBAAA;ACLZ;ADgBI;EACE,gBAAA;ACdN;ADgBM;EACE,aAAA;EACA,yBAAA;ACdR;ADeQ;EACE,eAAA;EACA,yBAAA;ACbV;;AA1CA;EACI,kBAAA;EACA,aAAA;AA6CJ","sourcesContent":["table {\n  border: 1px solid #dee2e6;\n  width: 100%;\n  border-collapse: collapse;\n\n\n  thead {\n    tr {\n      min-height: 56px;\n\n      th {\n        padding: 10px;\n        border: 1px solid #dee2e6;\n        text-align: left;\n        color: rgba(0, 0, 0, .85);\n        font-size: 14px;\n        font-weight: 500;\n\n        span {\n          &:hover {\n            cursor: pointer;\n          }\n        }\n\n        & > div {\n          display: flex;\n          flex-direction: row;\n          justify-content: space-between;\n          align-items: center;\n\n          & > div {\n            display: flex;\n            flex-direction: row;\n          }\n\n          fa {\n            margin-left: 10px;\n            color: rgba(0, 0, 0, 0.5);\n          }\n        }\n\n\n      }\n    }\n\n  }\n\n  tbody {\n    tr {\n      min-height: 25px;\n\n      td {\n        padding: 10px;\n        border: 1px solid #dee2e6;\n        span {\n          font-size: 14px;\n          color: rgba(0, 0, 0, 0.6);\n        }\n      }\n    }\n\n  }\n}\n","@import \"../../styles/table\";\n.model-action {\n    position: absolute;\n    right: 1.5rem;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
