import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from "@angular/forms";
import { TaskService } from '../../shared/services/task.service';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss']
})
export class AttachmentsComponent implements OnInit {

  @Input() form: UntypedFormGroup;
  @Input() uploadData;
  @Input() documentId;
  @Input() formType: string;
  @Input() taskType: string;
  public uploadNames;
  constructor(private taskService: TaskService, private alertService: AlertService) { }

  ngOnInit() {
    console.log('formType: ' + this.formType);
    console.log('taskType: ' + this.taskType);
    this.uploadNames = this.uploadData
      .filter((item) => {
        // display only elements which start with attachment: keyword
        return item.key.startsWith('attachment:');
      })
      .map((item) => {
        const splittedItem = item.key.split(':');
        return {          
          name: decodeURI(splittedItem[splittedItem.length - 1]),
          url: item.url
        };
      });
      console.log('firstTimeRun: ' + this.form.get('firstTimeRun').value);
  }

  openDocument(url) {
    window.open(url + '?s=' + sessionStorage.getItem('session'));
  }

  public fileChangeEvent(fileInput: any){
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.taskService.uploadAttachment(fileInput.target.files[0], this.documentId).subscribe(res => {
        console.log('file ' + fileInput.target.files[0].name + ' uploaded');
        //the upload was successful, add the file to the uploadNames array
        this.uploadNames.push({
          name: fileInput.target.files[0].name,
          url: null
        });
      },
      error => {
        console.log(error);
        this.alertService.createAlert('ATTACHMENT.ERROR', 'error');
      });
    }
  }

}
