import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import {UntypedFormControl} from "@angular/forms";

@Component({
  selector: 'app-reject-modal',
  templateUrl: './reject-modal.component.html',
  styleUrls: ['./reject-modal.component.scss']
})
export class RejectModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<RejectModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: UntypedFormControl) { }

  ngOnInit() {
  }

  onYesClicked() {
    if(this.data.valid) {
      this.dialogRef.close(this.data);
    } else {
      this.data.markAsTouched();
    }
  }

}
