import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})

export class TaskService {
  private url = environment.url;
  public taskList;

  constructor(
    private http: HttpClient
  ) {}

  getTaskInformation(activityId, workflowId) {
    return this.http.get(this.url + '/Service/MyHomeService.svc/GetTaskInfo?workflowStepId=' + activityId + '&workflowInstanceId=' + workflowId);
  }

  rejectTask(body) {
    return this.http.post(this.url + '/Service/MyHomeService.svc/AddComment', body);
  }

  deleteTask(body) {
    return this.http.post(this.url + '/Service/WorkflowService.svc/RemoveWorkflowInstances', body);
  }

  makeActionWithTask(body) {
    return this.http.post(this.url + '/Service/WorkflowService.svc/MessageWorkflow', body);
  }

  sendMessage(body) {
    return this.http.post(this.url + '/Service/MyHomeService.svc/AddComment', body);
  }

  getOrganisationNames(type,value, activityId?) {
    let date = new Date().getTime().toString();
    let params = new HttpParams()
      .set('name', type)
      .set('step', activityId)
      .set('configuration', '')
      .set('query', value)
      .set('_', date);
    return this.http.get(this.url + '/Service/MyHomeService.svc/ServerLookup', {params: params});
  }

  getDocument(url) {
    return this.http.get(url + '?s=' + sessionStorage.getItem('session'));
  }

  uploadAttachment(file: File, documentId) {
    const formData = new FormData();
    formData.append('task-attachment-document-id', documentId);
    formData.append('task-attachment-file', file);
    return this.http.post(this.url + '/Service/MyHomeService.svc/UploadAttachment', formData);
  }
}
