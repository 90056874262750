import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../shared/material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { TaskDetailsComponent } from './task-details.component';
import { TaskDetailsRoutingModule } from './task-details-routing.module';
import { FormsModule } from '@angular/forms';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { FirstTabComponent } from './first-tab/first-tab.component';
import { SecondTabComponent } from './second-tab/second-tab.component';
import { ThirdTabComponent } from './third-tab/third-tab.component';
import { ViewerComponent } from './viewer/viewer.component';
import { AttachmentsComponent } from './attachments/attachments.component';
import { FirstTabProofComponent } from './first-tab-proof/first-tab-proof.component';

@NgModule({
  imports: [
    CommonModule,
    TaskDetailsRoutingModule,
    SharedModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    PdfViewerModule
  ],
  declarations: [
    TaskDetailsComponent,
    FirstTabComponent,
    FirstTabProofComponent,
    SecondTabComponent,
    ThirdTabComponent,
    ViewerComponent,
    AttachmentsComponent
  ]
})

export class TaskDetailsModule { }
